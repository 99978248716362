import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { NavLink } from 'react-router-dom';
import "./AnfragenandIncidents.css"
import return1 from '../../../Assests/dashboard/images/return1.svg';
import 'react-nice-dates/build/style.css'
import { DatePicker } from 'react-nice-dates'
import { de } from 'date-fns/locale'
import { Button } from 'react-bootstrap';
import { ListOfProblemMaiden, ListOfProblemMaiden1 } from '../../../Services/Allservices/Admin/adminservices';
import user from "../../../Assests/dashboard/images/user.svg"
import moment from 'moment';

import Loader from '../../Utills/Loader';
const AnfragenandIncidents = () => {
  const [ProblemMaiden, setProblemMaiden] = useState([])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyname, setCompanyName] = useState("")
  const [status, setStatus] = useState("")
  const [date, setdate] = useState("")
  const [UserType, setUserType] = useState("")
  const [loading, setloading] = useState(false)

  const getAllProblemMaiden = async () => {
    setFirstName("")
    setLastName("")
    setCompanyName("")
    setStatus(0)
    setUserType(0)
    setdate(null)
    setloading(true)
    try {
      const response = await ListOfProblemMaiden()
      setProblemMaiden(response?.data?.result)
      if (response?.data?.status) {
        setloading(false)
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const getAllProblemMaiden1 = async () => {
    setloading(true)
    try {
      const payload = {
        "CreatedDate": date ? moment(date).format("YYYY-MM-DD") : null,
        "UserType": UserType ? parseInt(UserType) : 0,
        "Status": status ? parseInt(status) : 0,
        "FName": firstName ? firstName : "",
        "LName": lastName ? lastName : "",
        "CompanyName": companyname ? companyname : ""
      }
      const response = await ListOfProblemMaiden1(payload)
      setProblemMaiden(response?.data?.result)
      if (response?.data?.status) {
        setloading(false)
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getAllProblemMaiden()
  }, [])
  return (
    <React.Fragment>
      {
        loading ?
          <Loader />
          :
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Anfragen & Incidents</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <DatePicker locale={de} date={date}
                            onDateChange={setdate}

                            format='dd MMM yyyy'>
                            {({ inputProps, focus }) => (
                              <input
                                className={'input form-control' + (focus === date ? ' -focused' : '')}
                                {...inputProps}
                                placeholder="Datum"
                              />
                            )}</DatePicker>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select defaultValue="Benutzertyp" name="UserType" value={UserType} onChange={(e) => setUserType(e.target.value)} className="form-control">
                            <option disabled={true} value="0" selected>Benutzertyp</option>
                            <option value="1">Location Anbieter</option>
                            <option value="2">Location Suchende</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select name="status" value={status} onChange={(e) => setStatus(e.target.value)} className="form-control">
                            <option disabled value="0" selected>Status</option>
                            <option value="1">Offen</option>
                            <option value="2">In Bearbeitung</option>
                            <option value="3">Geschlossen</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Firmename" name="companyname" value={companyname} onChange={(e) => setCompanyName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <Button className="theme_btn mt-0" onClick={getAllProblemMaiden1}>Suchen</Button>
                          <button type="button" className="theme_btn" onClick={getAllProblemMaiden}>Filter löschen</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="support_div">
                  <ul className="message_list">
                    {
                      ProblemMaiden && ProblemMaiden.length > 0 ?
                        ProblemMaiden.map((item) => (
                          <li key={item?.id}>
                            <div className="for_message">
                              <div className="img_round"><img src={item?.pictureUrl ? `https://venoon.com/API${item?.pictureUrl}` : user} alt="img" /></div>
                              <div className="text_message">
                                <h6>ID {item?.id}, {item?.firstName} {item?.lastName}, {item?.companyName ? item?.companyName : " "}<br />Nachricht: {item?.userQuery ? item?.userQuery : ""}</h6>
                                <p>Datum: {moment(item?.queryRaisedDate).format('DD.MM.YYYY')}  Uhrzeit:{moment(item?.queryRaisedDate).add(1,'hours').format('HH:mm:ss')}</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                {item.problemStatus === 3 ?
                                  <>
                                    <NavLink className=" msg_status_closed">Geschlossen</NavLink>
                                    <NavLink to="/replymessage" state={{ id: item?.id, status: item.problemStatus }} > <img src={return1} alt="img" /></NavLink>
                                  </>
                                  : item.problemStatus === 2 ?
                                    <>
                                      <NavLink className=" msg_status_process" >In Bearbeitung</NavLink>
                                      <NavLink to="/replymessage" state={{ id: item?.id, status: item.problemStatus }} ><img src={return1} alt="img" /></NavLink>
                                    </>
                                    : item.problemStatus === 1 ?
                                      <>
                                        <NavLink className="msg_status" >Offen</NavLink>
                                        <NavLink to="/replymessage" state={{ id: item?.id, status: item.problemStatus }} ><img src={return1} alt="img" /></NavLink>
                                      </>
                                      : <></>
                                }
                              </div>

                            </div>
                          </li>
                        ))
                        :
                        <div className="no_record"> Kein Eintrag gefunden!</div>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
      }
    </React.Fragment >
  )
}

export default AnfragenandIncidents