import axios from "axios";
import { getItemCookies } from "../Component/Utills/Utills";
const baseURL = "https://venoon.com/API"
const token = getItemCookies("auth_token")
export const axiosInstance = axios.create({

  headers: {
    'content-type': 'Application/json',
    'Accept': 'Application/json',
    "Authorization": `Bearer ${token}`
  }

})

export const formInstance = axios.create({

  headers: {
    'Accept': 'Application/json',
    "Authorization": `Bearer ${token}`,
    'Content-Type': "multipart/form-data"
  }

})

export const config = {
  Login: baseURL + '/Login/Token',
  SendContactUsEmail:baseURL + `/User/SendContactUsEmail`,
  SendAppLink:baseURL +`/Admin/SendAppLink`,
  Admindashboard: baseURL + '/Admin/GetAdminDashboard',
  Dashboard: baseURL + '/LocationProvider/GetLocationProviderDashboard',
  LocationList: baseURL + `/LocationProvider/LocationListForProvider?search=`,
  CheckSubscription: baseURL+ `/LocationProvider/CheckSubscription`,
  DeleteLocation: baseURL + '/LocationProvider/DeleteLocation',
  CopyLocation: baseURL + '/LocationProvider/CopyLocation',
  ProblemMaidenByProvider: baseURL + '/LocationProvider/ProblemMaidenByProvider',
  LocationDetailsForProvider: baseURL + '/LocationProvider/LocationDetailsForProvider',
  SaveLocation: baseURL + '/LocationProvider/SaveLocation',
  SavePhotosAndVideos: baseURL + '/LocationProvider/SavePhotosAndVideos',
  SaveLocationPrice: baseURL + '/LocationProvider/SaveLocationPrice',
  SaveLocationServices: baseURL + '/LocationProvider/SaveLocationServices',
  OccasionList: baseURL + '/Admin/OccasionList',
  DeletePhotosAndVideos: baseURL + '/LocationProvider/DeletePhotosAndVideos',
  ServicesList: baseURL + '/Admin/ServicesList',
  FeaturesList: baseURL + '/Admin/FeaturesList',
  SaveLocationFeatures: baseURL + '/LocationProvider/SaveLocationFeatures',
  SaveLocationCalender: baseURL + `/LocationProvider/SaveLocationCalender`,
  GetLocation: baseURL + `/LocationProvider/GetLocation`,
  GetPhotosAndVideos: baseURL + `/LocationProvider/GetPhotosAndVideos`,
  GetLocationPrice: baseURL + `/LocationProvider/GetLocationPrice`,
  GetLocationServices: baseURL + `/LocationProvider/GetLocationServices`,
  GetLocationFeatures: baseURL + `/LocationProvider/GetLocationFeatures`,
  GetLocationDates: baseURL + `/LocationProvider/GetLocationDates`,
  UserRegistration: baseURL + `/User/UserRegistration`,
  UserProfilePicture: baseURL + `/User/UserProfilePicture`,
  ChangePassword: baseURL + `/User/ChangePassword`,
  GetUser: baseURL + `/User/GetUserById`,
  DeleteProfilePicture:baseURL + `/User/DeleteProfilePicture`,
  SkipLocationCalenderAvailability :baseURL+`/LocationProvider/SkipLocationCalenderAvailability`,
  GetAllAnnouncement:baseURL+`/Admin/GetAllAnnouncement`,
  AnnoucmentByAdmin:baseURL+`/Admin/AnnoucmentByAdmin`,
  DeleteAndUpdateAnnouncement:baseURL+`/Admin/DeleteAndUpdateAnnouncement`,
  ListOfProblemMaiden: baseURL+`/Admin/ListOfProblemMaiden`,
  GetProblemById:baseURL+`/Admin/GetProblemById`,
  GetUserProfile:baseURL+`/Admin/GetUserProfile`,
  GetManageInvoice:baseURL+`/Admin/GetManageInvoice`,
  DeleteUser:baseURL+`/User/DeleteUser`,
  ProblemSolutionByAdmin:baseURL+`/Admin/ProblemSolutionByAdmin`,
  LocationListForAdmin:baseURL+`/Admin/LocationListForAdmin`,
  GetReservationList:baseURL+`/Admin/GetReservationList`,
  ActiveDeactiveLocation:baseURL+`/Admin/ActiveDeactiveLocation`,
  ActiveDeactiveUser:baseURL+`/User/ActiveDeactiveUser`,
  GetUserProfilesById:baseURL+`/Admin/GetUserProfilesById`,
  
  Logout:baseURL+`/Notification/Logout`,
  SubscriptionTypeList:baseURL+`/Admin/SubscriptionTypeList`,
  GetUserSubscription: baseURL +`/Admin/GetUserSubscription`,
  ActiveDeactiveUserByAdmin: baseURL +`/Admin/ActiveDeactiveUserByAdmin`,
  SubscriptionTypeDelete:baseURL +`/Admin/SubscriptionTypeDelete`,
  verifylocation:baseURL+`/Admin/VarifedReservation`,
  ProblemStatusByAdmin:baseURL+ `/Admin/ProblemStatusByAdmin`,
  BookLocation:baseURL+'/LocationProvider/BookLocation',
  SaveInvoiceDocument :baseURL+`/Admin/SaveInvoiceDocument`,
  ProviderPaymentUpDate:baseURL +`/Admin/ProviderPaymentUpDate`,
  DeleteUserById:baseURL+`/Admin/DeleteUserById`,
  InvoicePaymentDelete: baseURL+`/Admin/InvoicePaymentDelete`,
  ReservationDeleteByAdmin: baseURL+`/Admin/ReservationDeleteByAdmin`,
  InvoiceClick:baseURL+`/Admin/InvoiceClick`,
  DownloadInvoiceDocument:baseURL+ `/Admin/DownloadInvoiceDocument`,
  SearchLocationListForAdmin:baseURL+`/Admin/SearchLocationListForAdmin`,
  DropDownList:baseURL+`/Admin/DropDownList`,
  GetReservationSearch:baseURL+`/Admin/GetReservationSearch`,
 UserSubscriptionSearch:baseURL+`/Admin/UserSubscriptionSearch`,
 InvoiceListSearch: baseURL+`/Admin/InvoiceListSearch`,
 UserProfileSearch:baseURL+`/Admin/UserProfileSearch`,
 UserSubscriptionSearchAdmin:baseURL+`/Admin/UserSubscriptionSearchAdmin`,
 InvoiceListSearchAdmin:baseURL+`/Admin/InvoiceListSearchAdmin`,
 MailToAdmin:baseURL+`/Admin/MailToAdmin`


}

export const msg={
  blankemailinput:"Bitte eine gültige E-Mail-Adresse eingeben",
  blankpasswordinput:"Ein Passwort wird benötigt",
  correctuser:"Bitte eine gültige E-Mail-Adresse und ein gültiges Passwort eingeben",
  correctformat:"Es muss eine gültige E-Mail sein",
  Titel:"Titel ist erforderlich",
  Straße:"Straße ist erforderlich",
  Nr:"Nr. ist erforderlich",
  PLZ:"PLZ ist erforderlich",
  PLZNumber:"Bitte nur Ziffern eingeben",
  Ort:"Ort ist erforderlich",
  Foto_Video:"Bitte mindestens ein Bild auswählen",
  oldpassword:"Bitte dein aktuelles Passwort eingeben",
  newpassword:"Bitte neues Passwort eingeben",
  samepassword:"Bitte neues Passwort richtig eingeben, um zu bestätigen",
  confirmpassword:"Bitte neues Passwort bestätigen",
  ProblemMaiden:"Erforderlich",
  detailsplanning:"Eine Vorausplanungszeit ist erforderlich",
  detailsplanningdays:"Eine Vorausplanungszeit ist erforderlich (akzeptiert mindestens zwei Tage)",
  detailsplanningnum:"Bitte nur Ziffern eingeben",
  Anlass:"Bitte ein Anlass auswählen",
  PersonenVon: "Erforderlich",
  streetnumber:"Sonderzeichen sind nicht erlaubt",
 PersonenVon1:"Bitte nur Ziffern eingeben",
 PersonenVon2:"Bitte nur Ziffern eingeben",
 PersonenVon3:"UBitte nur Ziffern eingeben",
 PersonenBis: "Erforderlich",
PersonenBis1: "Bitte nur Ziffern eingeben",
 PreisVon: "Bitte nur Ziffern eingeben",
 PreisBis:"Bitte nur Ziffern eingeben",
 locationfloor:"Bitte nur Ziffern eingeben",
 locationarea:"Bitte nur Ziffern eingeben",
 foto_msg:"Sie haben das maximale Limit erreicht",
 video_msg:"Sie haben das maximale Limit erreicht",
 location_msg:"Maximale Anzahl an Location erreichtnding",
 smallmsg:"Dieser Wert darf nicht kleiner sein",
 firstnamekontakt:"Vorname ist erforderlich",
 lastnamekontakt:"Nachname ist erforderlich",
 userrolekonkat:"Sie sind ist erfoderlcih",
 emailkonkat:"E-Mail ist erforderlich",
 emailpatternkonkat:"Bitte geben Sie die gültige E-Mail-Adresse ein",
 mobilenokonkat:"Handynummer ist erforderlich",
 countrykonkat:"Land ist erforderlich",
 subjectkonkat:"Betreff ist erforderlich",
 messageKonkat:"Nachricht ist erforderlich",
 passwordpatterncheck:"Bitte die Passwortrichtlinie beachten",
 solution:"Nachricht ist erforderlich",
 postcodepatterncheck:"Bitte eine gültige PLZ eingeben",
 placepatterncheck:"Nur Buchstaben sind erlaubt"
}